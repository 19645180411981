.top_business_section {
  padding: 60px 0;
  background-color: #f0f1f7;
}
.top_business_section .heading {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44.8px;
  margin-bottom: 32px;
}
.top_business_section .advertise_card {
  border-radius: 32px;
  background-color: #fff;
  padding: 64px 50px;
}
.top_business_section .advertise_card .imgbox {
  max-width: 250px;
  aspect-ratio: 1/1;
  margin: 0 auto;
}
.top_business_section .advertise_card .body {
  margin-top: 16px;
}
.top_business_section .advertise_card .body .subheading {
  color: #808183;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 16px;
}
.top_business_section .advertise_card .body .heading {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.top_business_section .advertise_card .body .pera {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}
.top_business_section .advertise_card .body .list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: #2b273c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
}
.top_business_section .advertise_card .body .read_more {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #4b4e67;
  background-color: #4b4e67;
  color: #fff;
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  overflow: hidden;
  display: block;
}
.top_business_section .advertise_card .body .read_more::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.top_business_section .advertise_card .body .read_more:hover::before {
  width: 100%;
}
.top_business_section .advertise_card .body .read_more,
.top_business_section .advertise_card .body .read_more * {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .top_business_section .advertise_card {
    padding: 34px 20px;
  }
}

.business-slider .slick-slide {
  padding: 10px;
}

.business-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  margin: 10px;
}

.business-card:hover {
  transform: translateY(-5px);
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.business-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.business-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-top: 10px;
}

.total-sales {
  font-size: 14px;
  color: #777;
}

/* See All Button */
.see-all-container {
  margin-top: 20px;
  float: right;
}

.see-all-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease;
}

.see-all-btn:hover {
  background-color: #0056b3;
}

.top_business_section {
  padding: 60px 0;
}
.top_business_section .c_pera {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 60px;
}
.top_business_section .list_box {
  margin-bottom: 32px;
}
.top_business_section .list_box .list_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 16px;
  margin-bottom: 32px;
}
.top_business_section .list_box .list_card .text_box .title {
  color: #0b0b0b;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}
.top_business_section .list_box .list_card .text_box .pera {
  color: #292929;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.top_business_section .list_box .list_card .imgbox {
  width: 60px;
  flex-shrink: 0;
}
.top_business_section .slider_box {
  padding: 0 60px;
}
.top_business_section .slider_box .title {
  color: #3e3e3e;
  font-family: "Inter", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}
.top_business_section .slider_box .need_card {
  padding: 0 12px;
}
.top_business_section .slider_box .need_card .head {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
  margin-bottom: 24px;
}
.top_business_section .slider_box .need_card .head .imgbox {
  border-radius: 5px;
  aspect-ratio: 1/1;
}
.top_business_section .slider_box .need_card .body .title {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.top_business_section .need_slider .slick-arrow {
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background: #fff;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
}
.top_business_section .need_slider .slick-arrow.slick-prev {
  left: -50px;
}
.top_business_section .need_slider .slick-arrow.slick-next {
  right: -50px;
}

.top_business_section .need_slider .slick-arrow::before {
  color: #000;
}

@media only screen and (max-width: 768px) {
  .top_business_section .list_box .list_card {
    flex-direction: column-reverse;
    row-gap: 32px;
  }
  .top_business_section .list_box .list_card .text_box {
    text-align: center;
  }
  .top_business_section .slider_box {
    padding: 0 0px;
  }
  .top_business_section .need_slider .slick-arrow.slick-prev {
    left: 0px;
  }
  .top_business_section .need_slider .slick-arrow.slick-next {
    right: 0px;
  }
}
